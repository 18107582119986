:root {
  --size-2: 2px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-14: 14px;
  --size-16: 16px;
  --size-18: 18px;
  --size-20: 20px;
  --size-22: 22px;
  --size-24: 24px;
  --size-30: 30px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-50: 50px;
  --size-55: 55px;
  --size-60: 60px;
  --size-64: 64px;
  --size-70: 70px;
  --size-80: 80px;
  --size-90: 90px;
  --size-100: 100px;
  --size-120: 120px;
  --size-140: 140px;
  --size-170: 170px;
  --size-180: 180px;
  --size-200: 200px;

  --size-30-40: clamp(30px, calc(30px + 10 * var(--size)), 40px);
  --size-30-64: clamp(30px, calc(30px + 34 * var(--size)), 64px);
  --size-30-120: clamp(30px, calc(30px + 90 * var(--size)), 120px);
  --size-32-100: clamp(32px, calc(32px + 68 * var(--size)), 100px);
}
