@value _breakpointXl: 1280px;
@value _breakpointMd: 768px;

:root {
  --breakpoint-xl: _breakpointXl;
  --breakpoint-md: _breakpointMd;
}

:export {
  breakpointSm: 480px;
  breakpointMd: _breakpointMd;
  breakpointLg: 1024px;
  breakpointXl: _breakpointXl;
  breakpointXxl: 1350px;
  viewportSm: screen and (min-width: 480px);
  viewportMd: screen and (min-width: 768px);
  viewportLg: screen and (min-width: 1024px);
  viewportXl: screen and (min-width: 1350px);
}

/* heights */
@custom-media --viewport-height-md screen and (min-height: 700px);

/* widths */
@custom-media --viewport-sm screen and (min-width: 480px);
@custom-media --viewport-md screen and (min-width: 768px);
@custom-media --viewport-lg screen and (min-width: 1024px);
@custom-media --viewport-xl screen and (min-width: 1280px);
@custom-media --viewport-xxl screen and (min-width: 1350px);
