:root {
  --font-family-base: 'Scala Sans', sans-serif;
  --font-family-heading: 'Classical Garamond', serif;
  --font-family-alt: 'Dorothy Regular', serif;

  --font-weight-350: 350;
  --font-weight-400: 400;

  --line-height-uppercase-heading: 0.8;
  --line-height-heading: 1.15;
  --line-height-alt: 0.5;
  --line-height-small-heading: 1.15;
  --line-height-small-description: 1.3;
  --line-height-paragraph: 1.6;

  --letter-spacing-label: 0.0125em;
  --letter-spacing-heading: 0.02em;
  --letter-spacing-paragraph: 0.06em;
  --letter-spacing-button: 0.17em;

  /* Sizes */
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-22: 22px;
  --font-size-25: 25px;
  --font-size-28: 28px;
  --font-size-30: 30px;
  --font-size-32: 32px;
  --font-size-34: 34px;
  --font-size-40: 40px;
  --font-size-45: 45px;
  --font-size-56: 56px;
  --font-size-65: 65px;
  --font-size-70: 70px;
  --font-size-90: 90px;
  --font-size-148: 148px;

  --font-size-12-14: clamp(12px, calc(12px + 2 * var(--size)), 14px);
  --font-size-12-18: clamp(12px, calc(12px + 6 * var(--size)), 18px);
  --font-size-16-18: clamp(16px, calc(16px + 2 * var(--size)), 18px);
  --font-size-18-22: clamp(18px, calc(18px + 4 * var(--size)), 22px);
  --font-size-18-30: clamp(18px, calc(18px + 12 * var(--size)), 30px);
  --font-size-22-25: clamp(22px, calc(22px + 3 * var(--size)), 25px);
  --font-size-24-28: clamp(24px, calc(24px + 4 * var(--size)), 28px);
  --font-size-25-30: clamp(25px, calc(25px + 5 * var(--size)), 30px);
  --font-size-30-45: clamp(30px, calc(30px + 15 * var(--size)), 45px);
  --font-size-32-40: clamp(32px, calc(32px + 8 * var(--size)), 40px);
  --font-size-34-40: clamp(34px, calc(34px + 6 * var(--size)), 40px);
  --font-size-34-48: clamp(34px, calc(34px + 14 * var(--size)), 48px);
  --font-size-40-45: clamp(40px, calc(40px + 5 * var(--size)), 45px);
  --font-size-45-50: clamp(45px, calc(45px + 5 * var(--size)), 50px);
  --font-size-45-56: clamp(45px, calc(45px + 11 * var(--size)), 56px);
  --font-size-45-65: clamp(45px, calc(45px + 20 * var(--size)), 65px);
  --font-size-45-80: clamp(45px, calc(45px + 35 * var(--size)), 80px);
  --font-size-45-90: clamp(45px, calc(45px + 45 * var(--size)), 90px);
  --font-size-56-65: clamp(56px, calc(56px + 9 * var(--size)), 65px);
  --font-size-56-65: clamp(56px, calc(56px + 9 * var(--size)), 65px);
  --font-size-56-115: clamp(56px, calc(56px + 59 * var(--size)), 115px);
  --font-size-70-148: clamp(70px, calc(70px + 78 * var(--size)), 148px);
  --font-size-100-300: clamp(100px, calc(100px + 200 * var(--size)), 300px);
}
