@value _black: black;
@value _white: white;
@value _sand: sand;
@value _clay: clay;
@value _gray: gray;
@value _holiday: holiday;

:export {
  contextBlack: _black;
  contextWhite: _white;
  contextSand: _sand;
  contextClay: _clay;
  contextGray: _gray;
  contextHoliday: _holiday;
}

html {
  /*
  for almost all style contexts, this is the correct princicple
  html and [data-style-context=...] have the same specificity, so if you now
  change the --primary-color or --primary-background-color, the --button vars
  change too. We need to declare these on html instead of on :root because
  :root has a slightly higher specificity. So if we would define those on :root,
  when you change --primary-color, --button-text-color would still have the initial --primary-color
  */
  --button-text-color: var(--primary-background-color);
  --button-background-color: var(--primary-color);
  --button-ghost-or-icon-border-color: var(--primary-color-15);
  --button-hover-text-color: var(--primary-color);
  --button-hover-background-color: transparent;
  --button-ghost-or-icon-hover-border-color: var(--primary-color);
  --cutoff-gradient-color: var(--primary-background-color);
}

:root {
  --color-sand-100--06: rgba(250, 249, 247, 0.06);
  --color-sand-100--15: rgba(250, 249, 247, 0.15);
  --color-sand-100--40: rgba(250, 249, 247, 0.4);
  --color-sand-100--65: rgba(250, 249, 247, 0.65);

  --color-dark-900--06: rgba(26, 25, 25, 0.06);
  --color-dark-900--15: rgba(26, 25, 25, 0.15);
  --color-dark-900--40: rgba(26, 25, 25, 0.4);
  --color-dark-900--65: rgba(26, 25, 25, 0.65);

  --color-white--06: rgba(255, 255, 255, 0.06);
  --color-white--15: rgba(255, 255, 255, 0.15);
  --color-white--40: rgba(255, 255, 255, 0.4);
  --color-white--65: rgba(255, 255, 255, 0.65);

  /* excpetions, only used in style context */
  --color-exception-copper-light: #b8a188;
  --color-exception-clay-medium: #beb29c;
}

/* Global */
[data-style-context="_black"] {
  --primary-background-color-accent: var(--color-dark-600);
  --primary-color-accent: var(--color-clay-400);
  --secondary-color-accent: var(--color-sand-100);
  --shadow-casting-blend-mode: color-burn;

  --primary-background-color: var(--color-dark-900);
  --primary-color: var(--color-sand-100);

  --primary-color--15: var(--color-sand-100--15);
  --primary-color--40: var(--color-sand-100--40);

  --testimonial-color: var(--color-exception-copper-light);
  --testimonial-accent: var(--primary-color);

  --form-input-background: var(--color-sand-100-06);
  --form-input-placeholder-color: var(--color-sand-100-65);

  color-scheme: dark;
}

[data-style-context="_white"] {
  --primary-background-color-accent: var(--color-dark-600);
  --primary-color-accent: var(--color-clay-400);
  --secondary-color-accent: var(--color-dark-800);
  --shadow-casting-blend-mode: multiply;

  --primary-background-color: var(--color-white);
  --primary-color: var(--color-dark-900);

  --primary-color--15: var(--color-dark-900--15,);
  --primary-color--40: var(--color-dark-900--40);

  --testimonial-color: var(--color-exception-clay-medium);
  --testimonial-accent: var(--primary-color);

  --form-input-background: var(--color-dark-900--06);
  --form-input-placeholder-color: var(--color-dark-900--65);

  color-scheme: light;
}

[data-style-context="_clay"] {
  --primary-background-color-accent: var(--color-clay-300);
  --primary-color-accent: var(--color-clay-300);
  --secondary-color-accent: var(--color-sand-100);
  --shadow-casting-blend-mode: multiply;

  --primary-background-color: var(--color-clay-400);
  --primary-color: var(--color-white);

  --primary-color--15: var(--color-white--15);
  --primary-color--40: var(--color-white--40);

  --testimonial-color: var(--color-exception-clay-medium);
  --testimonial-accent: var(--primary-color);

  --form-input-background: var(--color-white--06);
  --form-input-placeholder-color: var(--color-white--65);

  color-scheme: light;
}

[data-style-context="_sand"] {
  --primary-background-color-accent: var(--color-dark-600);
  --primary-color-accent: var(--color-clay-400);
  --secondary-color-accent: var(--color-dark-800);
  --shadow-casting-blend-mode: multiply;

  --primary-background-color: var(--color-sand-200);
  --primary-color: var(--color-dark-900);

  --primary-color--15: var(--color-dark-900--15);
  --primary-color--40: var(--color-dark-900--40);

  --testimonial-color: var(--color-exception-clay-medium);
  --testimonial-accent: var(--primary-color);

  --form-input-background: var(--color-dark-900--06);
  --form-input-placeholder-color: var(--color-dark-900--65);

  color-scheme: light;
}

[data-style-context="_gray"] {
  --primary-background-color-accent: var(--color-dark-500);
  --primary-color-accent: var(--color-clay-400);
  --secondary-color-accent: var(--color-sand-100);
  --shadow-casting-blend-mode: multiply;

  --primary-background-color: var(--color-dark-600);
  --primary-color: var(--color-white);

  --primary-color--15: var(--color-white--15);
  --primary-color--40: var(--color-white--40);

  --testimonial-color: var(--color-exception-clay-medium);
  --testimonial-accent: var(--primary-color);

  --form-input-background: var(--color-white--06);
  --form-input-placeholder-color: var(--color-white--65);

  color-scheme: dark;
}

/* copied from _black */
[data-style-context="_holiday"] {
  --primary-background-color-accent: var(--color-dark-600);
  --primary-color-accent: var(--color-white);
  --secondary-color-accent: var(--color-sand-100);
  --shadow-casting-blend-mode: color-burn;

  --primary-background-color: transparent; /* we want to see page through all components */
  --primary-color: var(--color-white);

  --primary-color--15: var(--color-sand-100--15);
  --primary-color--40: var(--color-sand-100--40);

  --testimonial-color: var(--color-exception-copper-light);
  --testimonial-accent: var(--primary-color);

  --form-input-background: var(--color-sand-100--06);
  --form-input-placeholder-color: var(--color-sand-100--65);

  --button-text-color: var(--color-dark-900);
  --cutoff-gradient-color: var(--color-dark-800);

  color-scheme: dark;
}

[data-style-context] {
  transition:
    color 150ms cubic-bezier(0.65, 0, 0.35, 1),
    background-color 150ms cubic-bezier(0.65, 0, 0.35, 1);
}
