:root {
  --color-gray-300: #d9d9d9;
  --color-gray-400: #bababa;

  --color-white: #fff;
  --color-black: #000;

  /* Move to style context */
  --color-black--10: rgba(10, 10, 10, 0.1);
  --color-black--15: rgba(10, 10, 10, 0.15);
  --color-black--40: rgba(0, 0, 0, 0.4);

  --color-dark-500: #4f4949;
  --color-dark-600: #453f3f;
  --color-dark-800: #211f1f;
  --color-dark-900: #1a1919;

  --color-clay-300: #d2c9b8;
  --color-clay-400: #a99f8f;
  --color-clay-600: #443924;

  --color-copper-300: #b8a188;
  --color-copper-400: #9a7b69;

  --color-salmon-300: #f5caac;

  --color-sand-100: #faf9f7;
  --color-sand-200: #f6f4f2;

  --color-error: #ff8a8a;
  --color-soap: #98c3be;
}
